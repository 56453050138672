<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :defaultData="defaultData"
    v-if="!!states && !!allCustomers && !!allLawyers"
  />
  <div class="custom-absolute-loading" v-else>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FormPage from "@/components/penal-retail-helpers/FormPage";
import establishmentsFormConfig from "@/helpers/establishmentsFormConfig";
import { db } from "@/firebase/firebaseConfig";

let collectionName = "establishments";

export default {
  components: {
    FormPage,
  },
  computed: {
    ...mapGetters(["allCustomers", "allLawyers"]),
    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
    defaultData() {
      return {};
    },
  },
  data() {
    let rows = establishmentsFormConfig(this, true);

    return {
      states: null,
      collectionName,
      tabs: [{ name: `${collectionName}.tabs.general`, rows }],
    };
  },
  created() {
    this.$bind("states", db.collection("states").orderBy("alias"), {
      wait: true,
    });
  },

  methods: {
    async prevalidate(model, collection, errors) {
      const otherCodes = (
        await collection
          .where("code", "==", model.code)
          .where("customer.id", "==", model.customer.id)
          .get()
      ).docs.filter((x) => x.exists);
      if (otherCodes.length > 0) {
        errors.add({
          field: "code",
          msg: this.$t(`${collectionName}.validations.code.unique`),
        });
      }
    },
  },
};
</script>
