import countries from "@/data/generic/countries.json";
import { countryStates } from "@/helpers/utils";

let fields = (self, isForCreate) => {
  let fields1 = [];
  let fields2 = [];
  let fields3 = [];

  fields1.push({
    size: isForCreate ? "1/3" : "1/4",
    type: "input",
    attribute: "code",
    validation: "required",
  });
  fields1.push({
    size: isForCreate ? "1/3" : "1/4",
    type: "input",
    attribute: "alias",
    validation: "required",
  });
  fields1.push({
    size: isForCreate ? "1/3" : "1/4",
    type: "select",
    attribute: "customer",
    validation: "required",
    extra: {
      optionsFunction: () => self.allCustomers,
    },
  });
  if (!isForCreate) {
    fields1.push({
      size: "1/4",
      type: "select",
      attribute: "company",
      extra: {
        optionsFunction: () => self.companies,
      },
    });
  }

  fields2.push({
    size: "1/2",
    type: "input",
    attribute: "address",
    validation: "required",
  });
  fields2.push({
    size: "1/2",
    type: "input",
    attribute: "address2",
    validation: "",
  });
  fields2.push({
    size: "1/6",
    type: "input",
    attribute: "zip",
    validation: "required|digits:5",
  });
  fields2.push({
    size: "1/3",
    type: "input",
    attribute: "city",
    validation: "required",
  });
  fields2.push({
    size: "1/6",
    type: "enum",
    attribute: "country",
    validation: "required",
    extra: {
      enumType: "countries",
      options: countries,
    },
  });
  fields2.push({
    size: "1/3",
    type: "select",
    attribute: "state",
    validation: "required",
    disabledFunction: (data) => !data.country,
    extra: {
      optionsFunction: (m) =>
        m.country ? (m.country ? countryStates(m.country.id) : []) : [],
    },
  });

  fields3.push({
    size: "1/6",
    type: "input",
    attribute: "phone",
    validation: "digits:9",
  });
  fields3.push({
    size: "1/6",
    type: "input",
    attribute: "fax",
    validation: "digits:9",
  });
  fields3.push({
    size: "1/4",
    type: "select",
    attribute: "lawyer",
    validation: "required",
    extra: {
      optionsFunction: () => self.allLawyers,
    },
  });

  return [fields1, fields2, fields3].map((attributes) => ({
    attributes: attributes.map((x) => ({
      ...x,
      disabled: ["customer", "supercustomer"].includes(
        self.$store.state.AppActiveUser.role
      ),
    })),
  }));
};

export default fields;
